<template>
  <div class="cont">
    <el-backtop target=".cont">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <Header :title="$t('message.link.warriors')" />
    <div class="top-img">
      <img
        src="http://cdn.topartsintermational.com/warriors-s.jpg"
        width="100%"
      />
    </div>
    <div class="part">
      <div class="part1">
        <div class="part1-left">
          <img src="http://cdn.topartsintermational.com/warriors-img1.jpg" />
        </div>
        <div class="part1-right">
          <p>{{ setting.part1.des }}</p>
        </div>
      </div>
      <div class="part2">
        <h1>{{ setting.part2.title }}</h1>
        <div style="font-size: 18px; color: #f2f2f2">
          {{ setting.part2.des }}
        </div>
        <div class="part2-cont">
          <p>{{ setting.part2.cont }}</p>
        </div>
      </div>
      <div class="part3">
        <a
          class="part3-left"
          href="http://www.bmy.com.cn/html/public/zl/500yvr/bc726d2972a949c180c4f85216e6c5ea.html"
          target="_blank"
        >
          <p>{{ setting.partLeft.title }}</p>
          <p style="text-align: left">{{ setting.partLeft.des }}</p>
        </a>
        <a
          class="part3-right"
          href="http://www.bmy.com.cn/html/public/zl/500yvr/bc726d2972a949c180c4f85216e6c5ea.html"
          target="_blank"
        >
          <p>{{ setting.partRight.title }}</p>
          <p style="text-align: left">{{ setting.partRight.des }}</p>
        </a>
      </div>
      <div class="part4">
        <h1>{{ setting.part4.title }}</h1>
        <div style="font-size: 18px; color: #f2f2f2">
          {{ setting.part4.des }}
        </div>
        <div class="part4-cont">
          <div class="part4-cont-left">
            <p
              style="
                padding: 50px 30px 0 55px;
                line-height: 40px;
                font-size: 18px;
                text-indent: 2em;
              "
            >
              {{ setting.part4.cont }}
            </p>
          </div>
          <div class="part4-cont-right" @click="visible = true">
            <img
              src="http://cdn.topartsintermational.com/warriors-img7.jpg"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://cdn.topartsintermational.com/warriors-fir-s.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
    <el-dialog
      :visible.sync="visible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
    >
      <video
        src="http://cdn.topartsintermational.com/warriors-1.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../components/header"
import Footer from '../../components/footer'
export default {
  name: "Warriors",
  data () {
    return {
      setting: {
        part1: {
          des: this.$t('message.warriors.part1.des'),
        },
        part2: {
          title: this.$t('message.warriors.part2.title'),
          des: this.$t('message.warriors.part2.des'),
          cont: this.$t('message.warriors.part2.cont'),
        },
        partLeft: {
          title: this.$t('message.warriors.partLeft.title'),
          des: this.$t('message.warriors.partLeft.des'),
        },
        partRight: {
          title: this.$t('message.warriors.partRight.title'),
          des: this.$t('message.warriors.partRight.des'),
        },
        part4: {
          title: this.$t('message.warriors.part4.title'),
          des: this.$t('message.warriors.part4.des'),
          cont: this.$t('message.warriors.part4.cont'),
        },
      },
      dialogVisible: true,
      visible: false,
    }
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.cont {
  min-width: 1240px;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  background: url("http://cdn.topartsintermational.com/warriors-bg.jpg")
    no-repeat;
  background-size: cover;
}
.top-img {
  width: 100%;
  overflow: hidden;
}
.top-img img {
  transition: all 0.6s;
}
.top-img img:hover {
  transform: scale(1.2);
  transition: all 0.6s;
}
.part {
  width: 1200px;
  margin: auto;
  padding: 16px 0;
}
.part1 {
  display: flex;
  justify-content: space-between;
}
.part1-left {
  overflow: hidden;
}
.part1-left img {
  transition: all 0.6s;
}
.part1-left img:hover {
  transform: scale(1.2);
  transition: all 0.6s;
}
.part1-right {
  width: 700px;
  background: url("http://cdn.topartsintermational.com/warriors-img2.jpg")
    no-repeat;
  background-size: cover;
}
.part1-right p {
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  margin: 50px 80px auto;
  text-indent: 2em;
}
.part2 {
  text-align: center;
  color: #fff;
  padding: 16px 0;
  margin-top: 50px;
  line-height: 24px;
}
.part2 h1 {
  margin: 0;
  padding: 0;
  line-height: 36px;
}
.part2-cont {
  height: 520px;
  background: url("http://cdn.topartsintermational.com/warriors-img3.jpg")
    no-repeat;
  background-size: cover;
  font-size: 20px;
  text-indent: 2em;
  color: #efeaea;
  line-height: 50px;
  text-align: left;
}
.part2-cont p {
  margin: 0;
  padding: 140px 80px 0;
}
.part3 {
  display: flex;
  justify-content: space-between;
  color: #fff;
  line-height: 45px;
}
.part3 p {
  padding: 40px 40px 0;
  margin: 0;
  font-size: 18px;
}
.part3-left {
  display: block;
  width: 550px;
  height: 270px;
  background: url("http://cdn.topartsintermational.com/warriors-img4.jpg")
    no-repeat;
  background-size: cover;
  text-align: center;
  text-decoration: none;
  color: #efeaea;
}
.part3-right {
  display: block;
  width: 550px;
  height: 270px;
  text-align: center;
  background: url("http://cdn.topartsintermational.com/warriors-img5.jpg")
    no-repeat;
  background-size: cover;
  text-decoration: none;
  color: #efeaea;
}
.part4 {
  text-align: center;
  color: #fff;
  padding: 16px 0;
  margin-top: 50px;
  line-height: 24px;
}
.part4 h1 {
  margin: 0;
  padding: 0;
  line-height: 36px;
}
.part4-cont {
  display: flex;
  justify-content: space-between;
}
.part4-cont-left {
  width: 460px;
  height: 400px;
  background: url("http://cdn.topartsintermational.com/warriors-img6.jpg")
    no-repeat;
  background-size: cover;
  text-align: left;
}
.part4-cont-right {
  width: 660px;
  height: 400px;
  overflow: hidden;
}
.part4-cont-right img {
  transition: all 0.6s;
}
.part4-cont-right img:hover {
  transform: scale(1.2);
  transition: all 0.6s;
}
</style>