<!--
 * @Author: 月魂
 * @Date: 2020-12-01 16:46:45
 * @LastEditTime: 2021-01-20 15:57:43
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong-serbia\src\components\footer.vue
-->
<template>
  <div class="footer">
    <div class="cont">
      <div class="icon">
        <a
          href="http://twitter.com/share?url=https://cccsydney.org"
          target="_blank"
        >
          <img src="../assets/t.png" width="30px" height="30px" />
        </a>
        <a
          href="http://www.facebook.com/sharer.php?u=https://cccsydney.org"
          target="_blank"
        >
          <img src="../assets/f.png" width="30px" height="30px" />
        </a>
      </div>
      <div class="cont-menu">
        <!-- <p>Host:China Cultural Center（Sydney）</p>
        <p>Shaanxi Provincial Department of Cultureand Tourism</p>
        <p>
          Organizers：Shaanxi Wenyi Internatioanl Culture Development co., Ltd
        </p>
        <p>Special thanks:Emperor Qinshihuang's Mausoleum Site Museum</p> -->
        <el-row>
          <el-col :span="5">{{ $t("message.footer.left.title") }}</el-col>
          <el-col :span="19">
            <p>{{ $t("message.footer.left.item1") }}</p>
            <p>{{ $t("message.footer.left.item2") }}</p>
          </el-col>
        </el-row>
      </div>
      <div class="cont-menu">
        <!-- <p>Acknowledgment: Xi'an Glory Tang Culture & Arts Co., Ltd,</p>
        <p>
          Shaanxi sagacity film and television culture communication Co., Ltd
        </p>
        <p>Bilibili-Ancients Say Channel</p>
        <p>Prince Jiang and Brick of Philosophy</p> -->
        <el-row>
          <el-col :span="4">{{ $t("message.footer.right.title") }}</el-col>
          <el-col :span="20"
            ><p>{{ $t("message.footer.right.item") }}</p></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  width: 100%;
  background: #012545;
  padding-top: 56px;
  padding-bottom: 56px;
}
.cont {
  width: 1200px;
  margin: auto;
  display: flex;
}
.cont-menu {
  width: 45%;
  color: #fff;
  padding-left: 16px;
}
.cont-menu p {
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}
</style>